import React from 'react';
import PropTypes from 'prop-types';
import { Router } from '@reach/router';
import { isBrowser } from '@firsttable/functions';
import PromoTitle from '@firsttable/web-components/molecules/PromoTitle';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import Box from '@firsttable/web-components/atoms/Box';
import SignUp from '../components/organisms/Auth/SignUp';
import ThemeLayout from '../layouts/layout';
import usePageLoading from '../hooks/pageLoading';
import MissingRoute from '../layouts/MissingRoute';

const LoadingPromoItem = () => (
  <Box display={['block', 'flex']} maxWidth="860px" mb="30px">
    <Box flex={1} className="pulse-animation">
      <Box
        bg="grayscale.300"
        width={['60%', '41%']}
        height="19px"
        mb="38px"
        mt="10px"
      />
      <Box bg="grayscale.300" width="75%" height="12px" />
    </Box>
  </Box>
);

const GET_PROMO = gql`
  query getPromo($code: String!, $siteId: Int!) {
    PromoCode(code: $code, siteId: $siteId) {
      name
      freeCreditNice
      freeCredit
      freeCreditCurrency
    }
  }
`;

const InviteLayout = ({ inviteCode, ...props }) => {
  // promo code
  const { loading: promoLoading, data } = useQuery(GET_PROMO, {
    variables: {
      code: inviteCode,
      siteId: +process.env.GATSBY_SITE_ID,
    },
    fetchPolicy: 'network-only',
  });

  const loading = usePageLoading();
  if (!loading && isBrowser) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      utm_campaign: inviteCode,
      utm_medium: 'PromoCode',
      utm_source: 'PromoCode',
    });
  }

  return (
    <ThemeLayout>
      <SignUp isModal={false} inviteCode={inviteCode} {...props}>
        {promoLoading ? <LoadingPromoItem /> : <PromoTitle data={data} />}
      </SignUp>
    </ThemeLayout>
  );
};
InviteLayout.propTypes = {
  inviteCode: PropTypes.string,
};

InviteLayout.defaultProps = {};

const Invite = () => (
  <Router>
    <InviteLayout path="/invite/:inviteCode" />
    <MissingRoute default />
  </Router>
);
export default Invite;
